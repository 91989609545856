import React, { lazy, Suspense, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Snackbar, Alert, StyledEngineProvider, CssBaseline, Box, Typography } from '@mui/material';
import Loading from './components/layout/organisms/Loading/Loading';
import theme from './styles/theme';
import { acceptTermsOfUse, loadUser } from './actions/auth';
import { closeSnackbar, setTermsDialog, setUnagreedTermsByRole } from './actions/controls';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/routes/PrivateRoute';
import { connect, useSelector } from 'react-redux';

import store from './store';
import AlertDialog from './components/layout/templates/AlertDialog';
import { FileTextIcon } from './resources/icons';
import { Helmet } from 'react-helmet-async';
import { CSP_HEADER_ENUM } from './constants/security';
import AlertSnackBar from './components/layout/organisms/AlertSnackBar';
const NewUserActivation = lazy(() => import('./containers/accounts/auth/NewUserActivation'));
const NewUserSetPassword = lazy(() => import('./containers/accounts/auth/NewUserSetPassword'));
const ForgotPasswordWithOtp = lazy(() => import('./containers/accounts/auth/ForgotPasswordWithOtp'));
const ForgotPasswordConfirm = lazy(() => import('./containers/accounts/auth/ForgotPasswordConfirm'));
const PageNotFound = lazy(() => import('./components/layout/organisms/PageNotFound'));
const Dashboard = lazy(() => import('./containers/main/Dashboard'));
const Login = lazy(() => import('./containers/accounts/auth/Login'));
const SignUp = lazy(() => import('./containers/accounts/auth/SignUp'));
const RoleSelectionScreen = lazy(() => import('./containers/accounts/auth/RoleSelectionScreen'));
const AcceptInvitation = lazy(() => import('./containers/referrals/AcceptInvitation'));
const RetrieveReport = lazy(() => import('./containers/exams/RetrieveReport/RetrieveReport'));
const CountrySelection = lazy(() => import('./containers/management/PatientSignUp/CountrySelectionPage'));
const PatientSignUp = lazy(() => import('./containers/management/PatientSignUp/PatientSignUp'));
const VerifyAccount = lazy(() => import('./containers/accounts/auth/VerifyAccount'));
const MultiFactorAuth = lazy(() => import('./containers/accounts/auth/MultiFactorAuth'));

const App = (props) => {
  const { words: staticWords, message: changingLanResMSG } = useSelector((state) => state.handlingTranslation);

  const { controls, acceptTermsOfUse, setTermsDialog, setUnagreedTermsByRole } = props;
  React.useEffect(() => {
    props.loadUser();
  }, []);
  // React.useEffect(() => {
  //   setcustomSnackBar(changingLanResMSG);
  // }, [changingLanResMSG]);

  const alertDialogTermsOfUse = () => {
    const unagreedTermsByUserRole = controls.unagreedTermsByUserRole;
    const openTermsDialog = controls.openTermsDialog;
    return (
      <AlertDialog
        disableBackdropClick
        isOpen={openTermsDialog}
        icon={<FileTextIcon />}
        onConfirm={() => {
          acceptTermsOfUse({ ids: [unagreedTermsByUserRole[0].id] });
          setTermsDialog(false);
          setUnagreedTermsByRole(null);
        }}
        confirmLabel={staticWords.Confirm} //wanted to use primary button
        title={"We've Updated Our Terms"}
        description={
          <Typography>
            {staticWords.In_order_to_continue_using_TeleEye_MD__you_will_need_to_agree_with_our_latest_Terms_of_Use_}
            &nbsp;
            <a
              target="_blank"
              href={unagreedTermsByUserRole?.[0].text}
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {staticWords.Terms_of_Use}
            </a>
            .
          </Typography>
        }
      />
    );
  };

  const handleSnackbarClose = () => {
    store.dispatch(closeSnackbar());
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <Suspense fallback={<Loading isFullScreen />}>
            {CSP_HEADER_ENUM[process.env.REACT_APP_STAGE] && (
              <Helmet>
                <meta httpEquiv="Content-Security-Policy" content={CSP_HEADER_ENUM[process.env.REACT_APP_STAGE]} />
              </Helmet>
            )}

            <Switch>
              <Route path="/invitation_via_referral/:uid/" component={AcceptInvitation} />
              <Route path="/retrieve_report/:exam_ref_code/" component={RetrieveReport} />
              <Route
                path="/r/:exam_ref_code/"
                render={(props) => (
                  <Redirect
                    to={{
                      pathname: '/retrieve_report/' + props.match.params.exam_ref_code,
                      state: { from: props.location },
                    }}
                  />
                )}
              />
              <Route path={'/login/mfa/'} component={MultiFactorAuth} />
              <Route path={'/login/'} component={Login} />
              <Route path={'/accounts/verify_email/'} component={VerifyAccount} />
              <Route path={'/accounts/verify_mobile/'} component={VerifyAccount} />
              <Route path={'/register/role/'} component={RoleSelectionScreen} />
              <Route path={'/register/form/'} component={SignUp} />
              <Route path={'/register/:country/patient/'} component={PatientSignUp} />
              <Route path={'/register/:role/select_country/'} component={CountrySelection} />
              <Route path={'/accounts/activate/set_password/:uid/:token/'} component={NewUserSetPassword} />
              <Route path={'/accounts/activate/:uid/:token/'} component={NewUserActivation} />
              <Route path={'/forgot_password/'} component={ForgotPasswordWithOtp} />
              <Route path={'/accounts/forgot_password/:uid/:token/'} component={ForgotPasswordConfirm} />
              <PrivateRoute path={'/'} component={Dashboard} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
        </Router>
        <AlertSnackBar
          open={controls.snackBarOpen}
          onClose={handleSnackbarClose}
          severity={controls.type}
          message={controls.message}
        />
        {alertDialogTermsOfUse()}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state) => ({
  controls: state.controls,
});

export default connect(mapStateToProps, {
  loadUser,
  acceptTermsOfUse,
  setTermsDialog,
  setUnagreedTermsByRole,
})(App);
