import React from 'react';
import { t } from 'i18next';

import _ from 'lodash';

import {
  AMD_LITE,
  DR_FULL,
  DR_LITE,
  GC_LITE,
  GLAUCOMA_CDR,
  RETINO_SCAN,
  RETINO_SCAN_ADV,
  RETINO_SCAN_ALL_SERVICES,
  RETINO_SCAN_SERVICES,
} from '../constants/constants';
import { LR_RESULT_ENUMS } from '../constants/results';
import { EMAIL, EMAIL_REGEX, PHONE_NUMBER } from '../constants/validations';
import { calculateWorstCasesByImages, separateLeftRightImages } from './calculations';
import { BASIC_PERMISSIONS, PREMIUM_PERMISSIONS, STANDARD_PERMISSIONS, TIER_ORDER } from '../constants/subscriptions';
import { uiVisibleTexts } from '../translation/visibleTexts';

export const getValuesGenerateKeys = (arg) => {
  const replacingSymbols = [
    ' ',
    '\n',
    '’',
    ',',
    "'",
    '-',
    ':',
    '.',
    '=',
    '?',
    '&',
    '*',
    '%',
    '$',
    '#',
    '@',
    '!',
    '^',
    '(',
    ')',
    '+',
    '[',
    ']',
    '{',
    '}',
    '|',
    '/',
  ];
  let result = { name: arg };
  replacingSymbols.map((symbol) => {
    result.name = { ...result }.name?.replaceAll(symbol, '_');
  });
  return result.name;
};
export const transferValueGetKey = (text) => {
  const fetchingUiVisibleTexts = uiVisibleTexts.en;
  let keyName;
  Object.keys(fetchingUiVisibleTexts).forEach((key) => {
    if (fetchingUiVisibleTexts[key] === text) {
      keyName = key;
    } else if (!keyName) {
      keyName = text;
    }
  });
  return keyName;
};
export const applyTranslationToValues = () => {
  const translationWords = { ...uiVisibleTexts.en };
  Object.keys(translationWords).map(
    (textkey) => (translationWords[textkey] = t(transferValueGetKey(translationWords[textkey])))
  );
  return translationWords;
};

export const setLocalStorageWithExpire = (key, value, secondsToLive) => {
  const now = new Date();
  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    timeToLive: now.getTime() + secondsToLive * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpire = (key) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = new Date();
  if (now.getTime() > item.timeToLive) {
    // If the item is expired, delete the item from storage
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const sortMeetingByTime = (meetingList, isDescending = false) => {
  return [...meetingList].sort((meeting1, meeting2) => {
    const result = new Date(meeting1?.start_datetime).getTime() - new Date(meeting2?.start_datetime).getTime();
    return isDescending ? -result : result;
  });
};

export const sortMeetingByName = (meetingList, isDescending = false) => {
  return isDescending ? [...meetingList].sort().reverse() : [...meetingList].sort();
};

/**
 * conditionally wrap an element
 * @param {boolean} condition the condition to determine whether to wrap the element
 * @param {boolean} visible the condition to determine whether the element is visible
 * @param {ReactElement || DetailedReactHTMLElement || JSX.Element}  wrapper ConditionalWrapper component
 * @param {ReactElement || String} children the element to be wrapped
 * @returns {ReactElement || DetailedReactHTMLElement} wrapped children if condition is true otherwise return the children itself
 */
export const ConditionalWrapper = ({ condition, visible = true, wrapper, children }) => {
  if (!visible) {
    return null;
  }
  if (condition) {
    return React.cloneElement(wrapper, null, children);
  }
  return children;
};

export const allotObjectToTier = (planName, basicObj, standardObj, premiumObj) => {
  if (planName?.includes('Basic')) {
    return basicObj;
  } else if (planName?.includes('Standard')) {
    return standardObj;
  } else if (planName?.includes('Premium')) {
    return premiumObj;
  } else {
    // TODO error
  }
};

export const dictCheck = (dict, key) => {
  if (key in dict) {
    return dict[key];
  } else {
    return null;
  }
};

export const determineToolTipTitleContent = (requiredPermissions) => {
  let body = 'Upgrade your subscription if you wish to select this service';
  if (requiredPermissions.every((permission) => BASIC_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Basic Plan required!', body];
  } else if (requiredPermissions.every((permission) => STANDARD_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Standard Plan required!', body];
  }
  // else if (requiredPermissions.every(permission => (BASIC_PLUS_PERMISSIONS && STANDARD_PLUS_PERMISSIONS).indexOf(permission) > -1)) {
  //     return ["Plus or Premium Plan required!", body]
  // }
  else if (requiredPermissions.every((permission) => PREMIUM_PERMISSIONS.indexOf(permission) > -1)) {
    return ['Premium Plan required!', body];
  } else {
    return null;
  }
};

export const normalizePlanName = (name) => {
  let normalizedName = '';
  if (name) {
    if (name.includes('Basic')) {
      normalizedName = 'Basic';
    } else if (name.includes('Standard')) {
      normalizedName = 'Standard';
    } else if (name.includes('Premium')) {
      normalizedName = 'Premium';
    } else {
      return 'Not-Supported';
    }

    return normalizedName;
  }
};

export const getIndexInTierOrder = (planName) => {
  return TIER_ORDER.indexOf(planName);
};

/**
 * Sort service results based on retinoScan or retinoScan+
 * @param {array} serviceResults the results to categorize
 * @returns {array} an array contains sorted retinoScan or retinoScan+ service results
 */
export const categorizeServiceResults = (serviceResults = []) => {
  return serviceResults.reduce((acc, curr) => {
    if (RETINO_SCAN_ALL_SERVICES.includes(curr.service_type)) {
      const serviceIndex = RETINO_SCAN_ALL_SERVICES.indexOf(curr.service_type);
      // sort retinoScan services in GLAUCOMA_CDR and DR FULL
      acc[serviceIndex] = curr;
    }
    return acc;
  }, []);
};

/**
 * Combine service results into a single array
 * @param {array} serviceResults the results to combine
 * @returns {array} an array containing all service results
 */
export const combineServiceResults = (serviceResults = []) => {
  // Define the desired order of service types
  const order = serviceResults.some((result) => result.service_type === 5) ? [5, 9, 7, 4] : [3, 9, 7, 4];

  // Sort the serviceResults array based on the order array
  const sortedServiceResults = serviceResults
    .filter((el) => order.includes(el.service_type))
    .sort((a, b) => {
      return order.indexOf(a.service_type) - order.indexOf(b.service_type);
    });

  // Combine the sorted results into the first array of the two
  return sortedServiceResults.reduce((combinedResults, result) => {
    combinedResults.push(result);
    return combinedResults;
  }, []);
};
/**
 * process images to get formatted service results
 * @param {array} examEyeImages the images with service results
 * @returns {array} an array contains formatted service results
 */
export const getServiceResultsByImages = (examEyeImages) => {
  if (_.isEmpty(examEyeImages)) return [];
  const leftRightIndexMap = {
    0: LR_RESULT_ENUMS.LEFT,
    1: LR_RESULT_ENUMS.RIGHT,
  };

  const separatedLeftRightImages = separateLeftRightImages(examEyeImages);
  const separatedWorstImageGradingServices = Object.values(separatedLeftRightImages).map((leftOrRightImages) =>
    calculateWorstCasesByImages(leftOrRightImages)
  );
  let serviceResults = separatedWorstImageGradingServices.reduce(
    (serviceResults, worstImageGradingServices, currentIndex) => {
      worstImageGradingServices.map((imageGradingService) => {
        let { id, service_type, ...results } = imageGradingService;
        let serviceResult = serviceResults.find((serviceResult) => serviceResult.service_type === service_type);
        if (serviceResult) {
          serviceResult.laterality.push({
            laterality: leftRightIndexMap[currentIndex],
            ...results,
          });
        } else {
          serviceResult = {
            service_type: imageGradingService.service_type,
            laterality: [
              {
                laterality: leftRightIndexMap[currentIndex],
                ...results,
              },
            ],
          };
          serviceResults.push(serviceResult);
        }
      });
      return serviceResults;
    },
    []
  );
  return serviceResults.filter((serviceResult) =>
    [DR_LITE, DR_FULL, AMD_LITE, GC_LITE, GLAUCOMA_CDR].includes(serviceResult.service_type)
  );
};

/**
 * Creates processed results object for ease of use.
 * @param {object} service Service object
 * @returns {object} Object containing serviceType, leftEyeResult and rightEyeResult properties
 */
export const processServiceResults = (service = {}) => {
  return {
    serviceType: service.service_type,
    leftEyeResult: service.laterality?.find((laterality) => laterality.laterality === LR_RESULT_ENUMS.LEFT),
    rightEyeResult: service.laterality?.find((laterality) => laterality.laterality === LR_RESULT_ENUMS.RIGHT),
  };
};

export const toTrunc = (number, precision = 2) => {
  let regex = RegExp(`^\\d+(?:\\.\\d{0,${precision}})?`);
  if (typeof number === 'number') {
    return number.toString().match(regex)?.[0];
  }
};

/* Check if string is valid UUID */
export const checkIfValidUUID = (str) => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
};

/**
 * Strip string from pattern and limit to maxLength
 * @param str
 * @param pattern
 * @param maxLength
 * @returns {string}
 */
export const stripString = (str = '', pattern, maxLength) => {
  return str.replace(pattern, '').slice(0, maxLength);
};

export const isEmailOrPhoneNumber = (str, excludeDialCode = false) => {
  // Regular expression to match phone numbers
  let phoneRegex;
  if (excludeDialCode) {
    phoneRegex = /^[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}$/;
  } else {
    phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,4}$/;
  }
  // Check if the input string matches either of the regex patterns
  if (EMAIL_REGEX.test(str)) {
    return EMAIL;
  } else if (phoneRegex.test(str)) {
    return PHONE_NUMBER;
  }
};

/**
 * Parses error responses returned from the backend API.
 *
 * @param {object} body - The error response body from the backend.
 * @param {string} defaultMsg - The default error message to use if no specific message is found.
 * @returns {string} Parsed error message.
 */
export function parseError(body, defaultMsg) {
  // Use the provided default message if available, or a generic one.
  let message = defaultMsg || 'An error occurred, please try again later.';

  let field = '';

  // If the error response or error array is missing, return the default message.
  if (!body || !body.errors || !body.errors.length) {
    return defaultMsg;
  }

  // Extract the error message and field from the first error in the array.
  message = body?.errors?.[0]?.message;
  field = body?.errors?.[0]?.field;

  // Remove square brackets from the message, if present.
  if (message.startsWith('[') && message.endsWith(']')) {
    message = message.slice(1, -1);
  }

  // Convert underscores in field name to spaces.
  const fieldName = field ? field.replace(/_/g, ' ') : '';

  // If both the message and field name are available, format the error message with the field name.
  if (message && fieldName) {
    return `${fieldName.toUpperCase()}: ${message}`;
  }

  return message || defaultMsg;
}

/**
 * Check if a specific cookie exists in the current document.
 *
 * @param {string} cookieName - The name of the cookie to check for.
 * @returns {boolean} True if the cookie exists, false otherwise.
 */
export function checkCookie(cookieName) {
  // Split all cookies into an array and iterate through them.
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    // If the current cookie starts with the specified name, the cookie exists.
    if (cookie.startsWith(cookieName + '=')) {
      return true; // Cookie found
    }
  }
  // If no matching cookie is found, return false.
  return false; // Cookie not found
}
